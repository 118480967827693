<template>
  <v-card flat class="px-0">
    <v-card-title class="pt-0 pb-0 px-5">
      <v-row>
        <v-col cols="8" class="ma-0 pa-0">
          <h5>{{ !quoteObj._id ? $t('addQuote') : $t('updateQuote') }} {{ getStatusName() }}</h5>
        </v-col>
        <v-col cols="4" class="pa-1 ma-0 text-end">
          <template>
            <v-menu top :close-on-content-click="true" v-if="quoteObj.state === 'draft'">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark v-on="on" :loading="saveLoader" small>{{$t('saveAs')}}</v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in dropdownItems" :key="index" @click="item.callback">
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <!-- <v-btn color="info" class="ml-4" @click="downloadInvoice(false, '')" :loading="previewLoader" v-if="quoteObj._id" small>{{$t('quotePreview') }}</v-btn> -->
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pt-1 pb-1">
      <v-form ref="quoteForm">
        <v-row class="px-3">
          <v-col cols="12" lg="2" xs="12" md="2" class="pa-1" v-if="quoteObj.number">
            <v-text-field v-model="quoteObj.number" outlined dense :label="$t('quoteNumber')" hide-details :disabled="true"></v-text-field>
          </v-col>
          <v-col cols="12" lg="3" xs="12" md="4" class="pa-1">
            <v-autocomplete v-model="quoteObj.payment_term" :items="quotePaymentTerms" item-text="name" item-value="id"
              :label="$t('quotePaymentTerms')" outlined dense hide-details :disabled="disableFields">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="3" xs="12" md="4" class="pa-1">
            <v-autocomplete v-model="quoteObj.payment_type" :items="quotePaymentTypes" item-text="name" item-value="id"
              :label="$t('quotePaymentMethods')" outlined dense hide-details :disabled="disableFields">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="2" xs="12"  md="4" class="pa-1">
            <v-menu :close-on-content-click="false" v-model="quoteDate" transition="scale-transition" offset-y min-width="290px" :disabled="splDisableFields">
              <template v-slot:activator="{ on }">
                <v-text-field outlined hide-details v-model="quoteObj.date" :rules="$_requiredValidation" clearable :label="$t('quoteDate')"
                  prepend-icon="mdi-calendar" readonly v-on="on"
                  @click:clear="$nextTick(() => quoteDatePicker = null)" dense :disabled="splDisableFields">
                </v-text-field>
                </template>
                <v-date-picker no-title color="primary" @input="quoteDate = false" v-model="quoteDatePicker" :disabled="splDisableFields"
                  first-day-of-week='1'></v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" lg="2" xs="12"  md="4" class="pa-1">
            <v-menu :close-on-content-click="false" v-model="quoteDueDate" transition="scale-transition" offset-y min-width="290px" :disabled="disableFields">
              <template v-slot:activator="{ on }">
                <v-text-field outlined hide-details v-model="quoteObj.due_date" :rules="$_requiredValidation" clearable :disabled="disableFields"
                  :label="$t('quoteDueDate')"
                  prepend-icon="mdi-calendar" readonly v-on="on" @click:clear="$nextTick(() => quoteDuePicker = null)" dense>
                </v-text-field>
                </template>
                <v-date-picker no-title color="primary" @input="quoteDueDate = false" v-model="quoteDuePicker" :disabled="disableFields"
                  first-day-of-week='1'></v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" lg="3" xs="12"  md="4" class="pa-1">
            <v-textarea outlined hide-details dense :label="$t('quoteComments')" rows="3" v-model="quoteObj.comments"></v-textarea>
          </v-col>
        </v-row>
        <v-divider class="mt-6"></v-divider>
        <!-- <v-row>
          <v-col cols="6" lg="5">
            <template v-if="quoteObj.state === 'draft'">
              <v-btn color="error" small :disabled="disableActions" @click="showDeleteDialog = true">{{ $t('delete') }}</v-btn>
              <v-btn class="ml-2" small :disabled="disableAkonto" @click="addNewGroup" color="info">{{ $t('akonto') }}</v-btn>
              <small class="font-weight-medium font-size-78 ml-2" v-html="$t('quotePressHold')"></small>
            </template>
          </v-col>
          <v-spacer></v-spacer>
        </v-row> -->
        <div style="overflow-x:auto;" v-if="!$vuetify.breakpoint.xs">
          <v-simple-table dense class="dense_table invoiceProducts invoiceitems px-2" fixed-header width="100%">
          <template v-slot:default>
            <thead>
              <tr>
                <th :class="header.class" v-for="(header, index) in productHeaders" :key="index" :width="header.width">{{header.text}}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(element, index) in listOfQuoteItems">
                <tr :key="`${index}_item`" :style="{ background: (element.selected ? '#80808038' : (element.color ? element.color : ((index  % 2) === 0) ? '#f8f8ff' : '#ededfb'))}"
                  :class="(element.parent_row || element.is_parent_row) ? 'grouped_invoice' : ''"
                  @click.stop="quoteObj.state === 'draft' ? itemSelected(element) : false"
                  :draggable="(!element.is_parent_row && element.selected) ? true : false"
                  @dragover="allowDrop($event)" @dragstart="drag($event)" @drop="moveInvoiceItemToGroup(element)">
                  <td width="7%" v-if="element.product_id">
                    <v-text-field hide-details full-width readonly v-model="element.number"></v-text-field>
                  </td>
                  <td width="7%" v-else>
                    <v-text-field hide-details full-width @click.stop="" :readonly="element.product_id ? true : false" v-model="element.number" :disabled="disableFields"
                      :rules="element.number ? [(v) =>(v && v.length <= 10) || ``] : []"></v-text-field>
                  </td>
                  <td width="5%">
                  </td>
                  <td width="15%">
                    <v-text-field @click.stop="" v-model="element.description" hide-details full-width :placeholder="$t('typeHere')"
                     :disabled="disableFields" class="commentsArea textfieldstyle">
                      <template v-slot:append>
                        <v-icon class="mt-0" :disabled="!!element.comments" @click.stop="hideAndShowComments(element, index)">mdi-comment-text</v-icon>
                      </template>
                    </v-text-field>
                  </td>
                  <td width="8%">
                    <v-text-field @click.stop="" v-model="element.qty" hide-details full-width :placeholder="$t('typeHere')"
                      :rules="element.qty ? $_invoiceValidation : $_zeroSelectValidation" class="align-right" :style="element.qty ? 'background: none' : ''"
                      @change="doCalculation(index)" :disabled="disableFields">
                    </v-text-field>
                  </td>
                  <td width="5%">
                    <v-text-field hide-details full-width @click.stop=""  class="align-right"  v-model="element.unit" :disabled="disableFields"></v-text-field>
                  </td>
                  <td width="8%">
                    <v-text-field v-model="element.cost_price" :rules="element.cost_price ? $_invoiceValidation : $_zeroSelectValidation" hide-details full-width :placeholder="$t('typeHere')"  @click.stop=""  @change="doCalculation(index)"
                      class="align-right" :disabled="disableFields">
                    </v-text-field>
                  </td>
                  <td width="8%">
                    <v-text-field v-model="element.sale_price" hide-details :rules="element.sale_price ? $_invoiceValidation : $_zeroSelectValidation" full-width :placeholder="$t('typeHere')"  @click.stop=""  @change="doCalculation(index)"
                      class="align-right" :disabled="disableFields">
                    </v-text-field>
                  </td>
                  <td width="10%">
                    <v-text-field @click.stop="" v-model="element.discount" hide-details full-width :placeholder="$t('typeHere')" :rules="element.discount ? $_discountValidation : []"
                      class="align-right" @change="doCalculation(index)" :disabled="disableFields">
                    </v-text-field>
                  </td>
                  <td width="11%"><v-text-field v-model="element.sum_ex_vat" class="align-right" readonly hide-details full-width></v-text-field></td>
                  <td width="8%">
                    <v-text-field @click.stop="" v-model="element.vat" hide-details full-width :placeholder="$t('typeHere')" :rules="element.vat ? $_discountValidation : []"
                      class="align-right" @change="doCalculation(index)" :disabled="disableFields">
                    </v-text-field>
                  </td>
                  <td width="7%"><v-text-field v-model="element.sum_inv_vat" class="align-right" readonly hide-details full-width></v-text-field></td>
                  <td width="2%">
                    <v-btn class="mx-2" text fab dark small color="primary" @click="removeProducts(index, element)">
                      <v-icon dark>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr v-if="element.show_comments" :style="{ background: element.selected ? '#80808038' : element.color }" :class="(element.parent_row || element.is_parent_row) ? 'grouped_invoice' : ''" :draggable="(!element.is_parent_row && element.selected) ? true : false" :key="`${index}_comments`">
                  <td :colspan="8" class="pa-1 pl-0 pr-0">
                    <v-textarea :rows="0" :disabled="disableFields" auto-grow class="comments_invoice mt-1" hide-details outlined v-model="element.comments"></v-textarea>
                  </td>
                  <td :colspan="8">
                    <v-btn class="mx-2" text fab dark small color="primary" @click.stop="removeFromGroup(element)" :disabled="disableFields">
                      <v-icon dark>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
                <tr>
                <td class="pl-0 pr-0" colspan="4">
                  <v-combobox v-if="quoteObj.state === 'draft'" item-text="productName" v-model="activeProduct" item-value="id" :disabled="disableFields"
                    append-icon="mdi-plus" ref="searchInput" :items="listOfSearchedProducts" dense
                    @keyup="$event.keyCode !== 13 ? loadProducts(`searchInput`, $event) : ''" autocomplete="off"
                    outlined hide-details :placeholder="$t('product_searchProduct')">
                    <template v-slot:append>
                      <v-btn fab dark x-small color="primary" class="mb-1" @click="getUnitHandler">
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </v-combobox>
                </td>
                <td colspan="3"></td>
                <td class="text-right" style="padding: 0 2px"><b>{{ $t('quoteSum') }}</b></td>
                <td class="text-right" style="padding: 0 2px"><b>{{ totalValues.sum }}</b></td>
                <td class="text-right" style="padding: 0 2px"><b>{{ totalValues.mva }}</b></td>
                <td class="text-right" style="padding: 0 2px"><b>{{ totalValues.invoiceTotal }}</b></td>
                <td></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </div>
        <div v-else class="px-0">
          <v-card>
              <v-expansion-panels multiple v-model="validationFailedIndexes">
                <v-expansion-panel v-for="(element, index) in listOfQuoteItems" :key="`${index}_item`">
                    <v-expansion-panel-header class="d-flex justify-space-between py-0">
                    <v-row>
                      <v-col cols="9" class="title" style = "text-transform:capitalize">{{ element.description }}</v-col>
                      <v-col cols="1"><v-icon color="error" @click="removeProducts(index, element)">mdi-delete</v-icon></v-col>
                      <v-col cols="1" class="mx-0"></v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-divider></v-divider>
                  <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="6" v-if="element.product_id">
                            <v-text-field hide-details readonly v-model="element.number"></v-text-field>
                        </v-col>
                        <v-col cols="6" v-else>
                          <v-text-field hide-details @click.stop="" :readonly="element.product_id ? true : false" v-model="element.number" :disabled="disableFields"
                                :rules="element.number ? [(v) =>(v && v.length <= 10) || ``] : []" :label="$t('Module_Product')"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field @click.stop="" v-model="element.description" hide-details :placeholder="$t('typeHere')" :label="$t('description')"
                            :disabled="disableFields" class="commentsArea textfieldstyle">
                            <template v-slot:append>
                              <v-icon class="mt-0" :disabled="!!element.comments" @click.stop="hideAndShowComments(element, index)">mdi-comment-text</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field @click.stop="" v-model="element.qty" hide-details :placeholder="$t('typeHere')" :label="$t('quoteQty')"
                            :rules="element.qty ? $_invoiceValidation : $_zeroSelectValidation" class="align-left" :style="element.qty ? 'background: none' : ''"
                            @change="doCalculation(index)" :disabled="disableFields"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field hide-details @click.stop="" :label="$t('quoteUnit')" class="align-left"  v-model="element.unit" :disabled="disableFields"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="element.cost_price" :label="$t('quoteCostPrice')" :rules="element.cost_price ? $_invoiceValidation : $_zeroSelectValidation" hide-details :placeholder="$t('typeHere')"  @click.stop=""  @change="doCalculation(index)" class="align-left" :disabled="disableFields"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="element.sale_price" :label="$t('quoteSalePrice')" hide-details :rules="element.sale_price ? $_invoiceValidation : $_zeroSelectValidation" :placeholder="$t('typeHere')"  @click.stop=""  @change="doCalculation(index)" class="align-left" :disabled="disableFields"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field @click.stop="" v-model="element.discount" :label="$t('quoteDiscount')" hide-details :placeholder="$t('typeHere')" :rules="element.discount ? $_discountValidation : []"
                                class="align-left" @change="doCalculation(index)" :disabled="disableFields"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="element.sum_ex_vat" :label="$t('quoteSumExclTax')" class="align-left" readonly hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6"><v-text-field @click.stop="" :label="$t('quoteTax')" v-model="element.vat" hide-details :placeholder="$t('typeHere')" :rules="element.vat ? $_discountValidation : []"
                                class="align-left" @change="doCalculation(index)" :disabled="disableFields"></v-text-field></v-col>
                        <v-col cols="6"><v-text-field v-model="element.sum_inv_vat" :label="$t('quoteSumIncTax')" class="align-left" readonly hide-details></v-text-field></v-col>
                      </v-row>
                  </v-expansion-panel-content>
                  <v-expansion-panel-content v-if="element.show_comments">
                    <v-row :style="{ background: element.selected ? 'white' : element.color }" :class="(element.parent_row || element.is_parent_row) ? 'grouped_invoice' : ''" :draggable="(!element.is_parent_row && element.selected) ? true : false" :key="`${index}_comments`">
                        <v-col cols="10" class="pa-1 mx-0">
                          <v-textarea :rows="0" :disabled="disableFields" auto-grow class="comments_invoice mt-1" hide-details outlined v-model="element.comments"></v-textarea>
                        </v-col>
                        <v-col cols="2">
                          <v-btn class="mx-0" text fab dark small color="primary" @click.stop="removeFromGroup(element)" :disabled="disableFields">
                            <v-icon dark>mdi-close-circle-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
        </div>
        <br />
        <v-card flat tile v-if="$vuetify.breakpoint.xs">
          <v-row class="px-3">
            <v-col :cols="$vuetify.breakpoint.xs ? '12' : '6'">
                <v-combobox v-if="quoteObj.state === 'draft'" item-text="productName" v-model="activeProduct" item-value="id" :disabled="disableFields"
                  append-icon="mdi-plus" ref="searchInput" :items="listOfSearchedProducts" dense
                  @keyup="$event.keyCode !== 13 ? loadProducts(`searchInput`, $event) : ''" autocomplete="off"
                  outlined hide-details :placeholder="$t('product_searchProduct')">
                  <template v-slot:append>
                    <v-btn fab dark x-small color="primary" class="mb-1" @click="getUnitHandler">
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                </v-combobox>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? '12' : '6'">
                <v-card-text class="black--text text-right py-2">{{ $t('total') }}&nbsp;&nbsp;{{ $t('quoteSumExclTax') }} :&nbsp;&nbsp;<b>{{ totalValues.sum }}</b></v-card-text>
                <v-card-text class="black--text text-right py-2">{{ $t('total') }}&nbsp;&nbsp;{{ $t('quoteSumIncTax') }} :&nbsp;&nbsp;<b>{{ totalValues.mva }}</b></v-card-text>
                <v-card-text class="black--text text-right pt-2 pb-0">{{ $t('total') }} :&nbsp;&nbsp;<b>{{ totalValues.invoiceTotal }}</b></v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-card-text>
     <!-- <v-dialog v-model="showDeleteDialog" persistent max-width="500px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('deleteConfirm') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <span class="pl-2">{{ $t('message.common.areYouSure') }}</span>
          <template v-if="isChildSelected">
            <v-checkbox hide-details v-model="onlyDeleteFromGroup" label="Only remove from group?"></v-checkbox>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" id="deleteConfirm" :loading="deleteLoading" @click="removeSelectedItems">{{ $t('yes') }}</v-btn>
          <v-btn color="primary" id="deleteClose" @click="closeDeleteDialog">{{ $t('no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-card>
</template>
<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      previewLoader: false,
      quoteObj: {
        _id: '',
        // date: this.$moment().format('DD.MM.YYYY'),
        // due_date: this.$moment().add(10, 'days').format('DD.MM.YYYY'),
        completed_date: '',
        payment_term: 10,
        state: 'draft'
      },
      quoteDatePicker: this.$moment().format('YYYY-MM-DD'),
      quoteDuePicker: this.$moment().add(10, 'days').format('YYYY-MM-DD'),
      quoteDate: false,
      quoteDueDate: false,
      disableFields: false,
      splDisableFields: false,
      dropdownItems: [
        { text: this.$t('quoteSaveAsDraft'), id: 0, callback: () => this.saveAsHandler(1, '') },
        { text: this.$t('quoteCompleteAndDownloadPDF'), id: 2, callback: () => this.saveAsHandler(2, '') }
      ],
      listOfQuoteItems: [],
      totalValues: {},
      saveLoader: false,
      loading: false,
      listOfCustomers: [],
      previewLoader: false,
      creatingCreditNote: false,
      listOfSearchedProducts: [],
      activeProduct: '',
      getCustomerId: null,
      confirmDialog: false,
      MODULE_URL: 'quotes',
      vatList: [],
      exportLoading: false,
      parentCheck: false,
      isChildSelected: false,
      disableActions: true,
      disableAkonto: false,
      showDeleteDialog: false,
      deleteLoading: false,
      saveInvoiceItemsModel: [],
      addNewGroupCount: 0,
      headersForInvoiceItems: [],
      ehfLoader: false,
      emailIds: '',
      listOfOrderChecklist: [],
      listOfAllOrderedChecklist: [],
      showEmailDialog: false,
      listOfUnits: [],
      hasPermission: true,
      deletedIndex: '',
      validationFailedIndexes: []
    }
  },
  created () {
  this.quoteObj.date =  this.$moment().format(this.userDetails.dateformat),
  this.quoteObj.due_date =  this.$moment().add(10, 'days').format(this.userDetails.dateformat)
  },
  mounted () {
    this.getSingleRecordHandler()
    let text = this.$t('deleteMsg')
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.MODULE_URL) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.listOfQuoteItems.splice(this.deletedIndex, 1)
      }
    })
  },
  watch: {
    quoteDatePicker (val) {
      if (val) {
        this.quoteObj.date = this.$formatter.formatDate(val, 'YYYY-MM-DD', this.userDetails.dateformat)
      }
    },
    quoteDuePicker (val) {
      if (val) {
        this.quoteObj.due_date = this.$formatter.formatDate(val, 'YYYY-MM-DD', this.userDetails.dateformat)
      }
    }
  },
  computed: {
      ...mapGetters(['userDetails']),
    productHeaders() {
      return [{
          text: this.$t('Module_Product'),
          value: 'product_number',
          width: '6%',
          reportWidth: '8%',
          class: 'text-left'
        }, {
          text: '',
          value: '',
          reportWidth: '',
          width: '5%',
          class: 'text-left'
        }, {
          text: this.$t('description'),
          value: 'description',
          reportWidth: '20%',
          width: '10%',
          class: 'text-left'
        }, {
          text: this.$t('quoteQty'),
          value: 'qty',
          showRequired: true,
          reportWidth: '8%',
          width: '5%',
          class: 'text-right'
        }, {
          text: this.$t('quoteUnit'),
          value: 'productunit_name',
          reportWidth: '7%',
          width: '5%',
          class: 'text-right'
        }, {
          text: this.$t('quoteCostPrice'),
          value: 'cost_price',
          reportWidth: '7%',
          width: '5%',
          class: 'text-right'
        }, {
          text: this.$t('quoteSalePrice'),
          value: 'sale_price',
          reportWidth: '7%',
          width: '5%',
          class: 'text-right'
        }, {
          text: this.$t('quoteDiscount'),
          value: 'discount',
          width: '6%',
          reportWidth: '7%',
          class: 'text-right'
        }, {
          text: this.$t('quoteSumExclTax'),
          value: 'sum_ex_vat',
          width: '11%',
          reportWidth: '9%',
          class: 'text-right'
        }, {
          text: this.$t('quoteTax'),
          value: 'vat',
          width: '6%',
          reportWidth: '7%',
          class: 'text-right'
        }, {
          text: this.$t('quoteSumIncTax'),
          value: 'Sum_Inv_Vat',
          width: '11%',
          reportWidth: '12%',
          class: 'text-right'
        }, {
          text: '',
          value: 'actions',
          width: '1%',
          reportWidth: '5%'
        }]
    }
  },
  methods: {
    getQuote () { },
    hideAndShowComments (element, index) {
      let model = this.listOfQuoteItems[index]
      model.show_comments = !model.show_comments
      this.$set(this.listOfQuoteItems, index, model)
    },
    doCalculation (index) {
      let item = this.$formatter.cloneVariable(this.listOfQuoteItems)
      if (item[index].qty != null && item[index].qty != '' && item[index].sale_price != null && item[index].sale_price != '') {
        let model = this.$formatter.formatAndParseModel(item[index], ['qty', 'price', 'sum_ex_vat', 'sum_inv_vat', 'discount', 'vat', 'sale_price', 'cost_price'], 'replaceCommaWithDot')
        let total =  model.sale_price *  model.qty * (1 - (model.discount || 0) / 100)
        model.price = model.sale_price *  model.qty
        model.sum_ex_vat = total
        let vatValue = (model.vat || 0) / 100
        model.sum_inv_vat = total * (1 + vatValue)
        let transformData = { number: { list: ['qty', 'price', 'sum_ex_vat', 'sum_inv_vat', 'discount', 'vat', 'sale_price', 'cost_price'], format: 'replaceDotWithComma' } }
        model = this.$formatter.formatModel(model, transformData)
        this.$set(this.listOfQuoteItems, index, model)
        let getUpdatedItems = this.listOfQuoteItems // reason to reinit here is, it is updated with prop values
        const getParent = getUpdatedItems[index].is_parent_row ? getUpdatedItems[index] : getUpdatedItems.find(x => x._id === getUpdatedItems[index].parent_row)
        if(!model.is_parent_row){
          this.calculateAkontoSum(getParent) // calculate parent's sum value
        }
        this.calculateAllValues()
        // this.$store.commit('isChangesMade', true)
      }
    },
    calculateAllValues () {
      let listOfProducts = this.$formatter.cloneVariable(this.listOfQuoteItems)
      listOfProducts = this.$formatter.formatAndParseList(listOfProducts, ['qty', 'price', 'discount', 'sum_ex_vat', 'sum_inv_vat', 'vat', 'sale_price', 'cost_price'], 'replaceCommaWithDot')
      let totalValues = { sum: 0, mva: 0, invoiceTotal: 0, vat: 25, cost: 0, db: 0, dg: 0 }
      let calculateMVA = 0
      listOfProducts.forEach(element => {
        if (!element.parent_row) {
          totalValues.sum += element.sum_ex_vat ? element.sum_ex_vat : 0
          totalValues.cost += element.cost ? element.cost : 0
          totalValues.db += element.db ? element.db : 0
          calculateMVA += (element.sum_ex_vat * element.vat ) /100
        }
      })
      totalValues.mva = calculateMVA
      totalValues.dg = ((totalValues.db * 100) / (totalValues.sum))
      let grandTotal = totalValues.sum + totalValues.mva
      let value = grandTotal.toFixed(2)
      value = value - Math.floor(value)
      totalValues.rounding_value = value.toFixed(2)
      totalValues.invoiceTotal = Math.floor(grandTotal)
      totalValues.dg_percentage = totalValues.dg
      let transformData = { number: { list: ['sum', 'mva', 'invoiceTotal', 'discount'], format: 'replaceDotWithComma' } }
      totalValues = this.$formatter.formatModel(totalValues, transformData)
      this.totalValues = totalValues
    },
    // Searching products
    loadProducts (term, event) {
      if ([30, 32, 37, 38, 39, 40].indexOf(event.keyCode) > -1) return
      let searchText = this.$refs[term].lazySearch
      if (searchText !== null) {
        let queries = [
          { $match: { $or: [{ [`Data.name`]: { $options: 'i', $regex: searchText } }, { [`Data.number`]: { $options: 'i', $regex: searchText } }] } }
        ]
        this.$api.execute('post', 'moduledata/Product/query', queries).then((response) => {
          this.listOfSearchedProducts = []
          if (response.data.length > 0) {
            response.data.forEach(element => {
              let productName = element.data.number ? `${element.data.number} - ${element.data.name}` : element.data.name
              this.listOfSearchedProducts.push({ _id: element._id, ...element.data, productName })
            })
          }
        })
      }
    },
    getUnitHandler () {
      if (this.$refs.searchInput.lazySearch) {
        this.validationFailedIndexes.push(this.listOfQuoteItems.length)
        let product = this.activeProduct ? this.listOfSearchedProducts.find(x => x._id === this.activeProduct._id) : null
        let discount = this.quoteObj.customer_supply_discount_rate ? this.$formatter.replaceDotWithComma(this.quoteObj.customer_supply_discount_rate) : '0,00'
        if (product) {
          let salePrice = this.$formatter.replaceDotWithComma(product.saleprice)
          let costPrice = this.$formatter.replaceDotWithComma(product.costprice)
          let discount = this.$formatter.replaceDotWithComma(product.discount)
          // let netPrice = product.netprice
          this.listOfQuoteItems.push({ show_comments: false, number: product.number, product_id: product.id, vat: product.tax ? this.$formatter.replaceDotWithComma(product.tax) : '25,00',
            discount, sale_price: salePrice, cost_price: costPrice || '', price: '', unit: product.unit || '', description: product.name || '' })
          this.listOfSearchedProducts = []
          this.activeProduct = ''
          // this.$store.commit('isChangesMade', true)
        } else {
          this.listOfQuoteItems.push({ show_comments: false, product_number: '', description: this.$refs.searchInput.lazySearch, discount, sale_price: '', cost_price: '', vat: '25,00' })
          this.listOfSearchedProducts = []
          this.$refs.searchInput.lazySearch = ''
          this.activeProduct = ''
        }
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'noFieldsAdded' })
    },
    removeSelectedItems () {
      this.deleteLoading = true
      let getSelectedItems = this.listOfQuoteItems.filter(x => x.selected)
      let payload = { data: { ids: getSelectedItems.map(x => (x._id || 0)) } }
      this.$api.execute('delete', 'quoteitems/delete_multiple', payload)
        .then(resposnse => {
          let getList = this.$formatter.cloneVariable(this.listOfQuoteItems)
          this.listOfQuoteItems = getList.filter(x => !x.selected)
          for (let i = 0; i < getSelectedItems.length; i++) {
            let getParentId = getSelectedItems[i].parent_row
            if (getParentId) {
              let getParentObj = getList.find(x => x._id === getParentId)
              this.calculateAkontoSum(getParentObj)
            }
          }
          this.saveOrUpdateQuoteItems(this.quoteObj._id)
            .finally(() => {
              this.getQuoteItems(this.quoteObj._id)
            })
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'deleteSuccess' })
        })
        .finally(() => {
          this.closeDeleteDialog()
          this.deleteLoading = false
        })
    },
    closeDeleteDialog () {
      this.showDeleteDialog = false
    },
    getSingleRecordHandler () {
      this.$api.execute('get', `quotes/get_by_sale/${this.$route.params.record_id}`).then(response => {
        if (response && response.data) {
          let quoteObj = response.data
          let transformData = { date: { list: ['date', 'due_date'], from: 'DD.MM.YYYYTHH:mm:ss', to: this.userDetails.dateformat }, number: { list: ['sum_inv_vat', 'grand_total', 'discount'], format: 'replaceCommaWithDot' } }
          let model = this.$formatter.cloneVariable(quoteObj)
          model = this.$formatter.formatModel(model, transformData)
          this.quoteObj = model
          this.getQuoteItems(model._id)
        }
      })
    },
    getQuoteItems (id) {
      if (id) {
        this.loading = true
        // let query = {
        //   'filter': `quote_id eq ${id}`,
        //   'expand': [{
        //     'select': ['number', 'name', 'unitId'],
        //     'model': 'Product',
        //     'on_field': 'Id',
        //     'on_parent_field': 'Product_Id'
        //   }, {
        //     'select': ['name'],
        //     'model': 'ProductUnit',
        //     'on_field': 'Id',
        //     'on_parent_field': 'Unit_Id'
        //   }],
        //   'order_by': {
        //     field: 'order_index',
        //     asc_or_desc: 'asc'
        //   }
        // }
        let queries = [
        { $match: { $and: [ { Quote_Id: `${id}` } ] } },
        { $lookup: {
          let: { productId: { $toObjectId: '$Product_Id' } },
          from: 'Product',
          pipeline: [
            { $match: { $expr: { $eq: ['$_id', '$$productId'] } } },
            { $project: { 'Data.number': 1, 'Data.name': 1, 'Data.unit': 1  } }
          ],
          as: 'product_info' }
        },
        {
          $project: {
            Number: 1,
            Product_Id: 1,
            Description: 1,
            Qty: 1,
            Cost_Price: 1,
            Sale_Price: 1,
            Vat: 1,
            Sum_Ex_Vat: 1,
            Sum_Inv_Vat: 1,
            Comments: 1,
            Discount: 1,
            Parent_Row : 1,
            Is_Parent_Row: 1,
            Color: 1,
            Order_Index: 1,
            Unit: 1,
            Customer: { $arrayElemAt: ['$customer_info', 0] },
            User: { $arrayElemAt: ['$user_info', 0] }
          }
        },
        { $sort: { 'Order_Index': 1 } }
      ]
        this.$api.execute('post', `quoteitems/query`, queries).then((response) => {
          if (response.data.length > 0) {
            let invoiceItems = response.data
            let transformData = { number: { list: ['qty', 'price', 'discount', 'sum_ex_vat', 'vat', 'sum_inv_vat', 'sale_price', 'cost_price'], format: 'replaceDotWithComma' } }
            let model = this.$formatter.cloneVariable(invoiceItems)
            model = this.$formatter.formatListModel(model, transformData)
            let groupedItems = {}
            model.forEach(element => {
              element.show_comments = !!element.comments
            })
            groupedItems = this.$formatter.groupBy(model, 'parent_row')
            const items = []
            Object.keys(groupedItems).forEach((x) => {
              if (x == 'null') {
                items.push(...groupedItems[x])
              }
            })
            Object.keys(groupedItems).forEach((x) => {
              if (x != 'null') {
                let getIndex = items.findIndex(y => y._id == x)
                if (getIndex >= 0) {
                  items.splice(getIndex + 1, 0, ...groupedItems[x])
                }
              }
            })
            this.listOfQuoteItems = items
            this.calculateAllValues()
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
      } else this.loading = false
    },
    getUnitsList () {
      this.$api.execute('post', `productunits/query`, { filter: 'isactive eq 1' })
        .then(resposnse => {
            this.listOfUnits = resposnse.data
        })
    },
    async downloadInvoice (fromSendEmail, emailId) {
      this.previewLoader = true
      let getBase64Content = await this.getInvoiceReportContent()
      let model = { content: getBase64Content, filename: `faktura_${this.quoteObj.number || ''}.pdf` }
      return this.$api.execute('post', `${this.MODULE_URL}/generate_pdf/${this.quoteObj.id}`, model)
        .then((response) => {
          let fileName = response.data
          if (!fromSendEmail) {
            window.open(`${process.env.VUE_APP_BASE_URL}file/download?fileName=${fileName}`, '_blank')
          } else {
            let model = {
              'to': emailId,
              'subject': `Quote ${this.quoteObj.number}`,
              'filename': fileName,
              'visiblefilename': `${this.quoteObj.number}.pdf`
            }
            this.$api.execute('post', `${this.MODULE_URL}/share/${this.quoteObj.id}`, model)
          }
        })
        .finally(() => {
          this.previewLoader = false
        })
    },
    getStatusName () {
      if (this.quoteObj.state) {
        let result = this.quoteStatus.find(x => x.id === this.quoteObj.state)
        if (result) return `(${result.name})`
        else return ''
      }
    },
    getInvoiceReportContent () {
      let query = [{ $match: { $and: [ { _Id: `${this.quoteObj._id}` } ] } },
        { $lookup: {
          let: { customerId: { $toObjectId: '$Company' } },
          from: 'Contact',
          pipeline: [
            { $match: { $expr: { $eq: ['$_id', '$$customerId'] } } }
          ],
          as: 'customer_info' }
        }
      ]
      // let query = {
      //   expand: [{
      //     model: 'Customer',
      //     on_field: 'id',
      //     on_parent_field: 'customer_id'
      //   }, {
      //     select: ['id', 'name'],
      //     model: 'User',
      //     on_field: 'id',
      //     on_parent_field: 'our_reference'
      //   }, {
      //     select: ['id', 'number'],
      //     model: 'Order',
      //     on_field: 'id',
      //     on_parent_field: 'order_id'
      //   }],
      //   filter: `id eq ${this.quoteObj.id}`
      // }
      return this.$api.execute('post', `${this.MODULE_URL}/query`, query)
        .then((response) => {
          if (response && response.data) {
            return false
            let headers = this.$formatter.cloneVariable(this.productHeaders)
            // headers[1].value = 'comments'
            headers.splice(4, 1)
            headers.splice(9, 10)
            let data = this.$formatter.cloneVariable(this.listOfQuoteItems)
            data.forEach(element => {
              let number = element.product_number ? element.product_number : element.number
              element.product_number = number
            })
            if (data.length > 0) {
              let discount = data[0].discount
              let result = data.filter(x => x.discount === discount)
              if (result.length > 0 && result.length === data.length) {
                headers.splice(5, 1)
                headers[1].reportWidth = "37%"
              }
            }
            data = data.filter(x => !x.parent_row)
            response.data[0].totalValues = this.$formatter.cloneVariable(this.totalValues)
            response.data[0].invoice_date = response.data[0].invoice_date ? this.$formatter.formatDate(response.data[0].invoice_date, 'DD.MM.YYYYTHH:mm:ss', this.userDetails.dateformat) : response.data[0].invoice_date
            response.data[0].due_date = response.data[0].due_date ? this.$formatter.formatDate(response.data[0].due_date, 'DD.MM.YYYYTHH:mm:ss', this.userDetails.dateformat) : response.data[0].due_date
            let translationData = { our_ref: this.$t('message.invoice.ourReference'),
              your_ref: this.$t('message.invoice.yourReference'),
              invoiced_date: this.$t('message.invoice.invoicedDate'),
              due_date: this.$t('message.invoice.duedate'),
              invoice_num: this.$t('message.invoice.invoiceNumber'),
              acc_no: this.$t('message.settings.accountNo') }
            if (response.data[0].customer_company_id) { // Used to get the customer's invoice_to (customer) inorder to show the custom_number of Invoice_TO
              return this.$api.execute('get', `customers/${response.data[0].customer_company_id}`).then((result) => {
                  let getInvoiceToCustomer = result && result.data ? result.data.custom_number : ''
                  response.data[0] = { ...response.data[0], customer_custom_number: getInvoiceToCustomer }
                  let getBase64Content = processReport(headers, data, response.data[0], translationData)
                  return getBase64Content
                })
            } else {
              if (response.data.length > 0 && response.data[0].customer_id) {
                return this.$api.execute('get', `customeraddresses/get_first_or_default/${response.data[0].customer_id}`).then((result) => {
                  response.data[0] = { ...response.data[0], ...result.data }
                  let getBase64Content = processReport(headers, data, response.data[0], translationData)
                  return getBase64Content
                })
                } else {
                let getBase64Content = processReport(headers, data, response.data[0], translationData)
                return getBase64Content
              }
            }
          }
        })
    },
    async saveAsHandler (type, emailIds) {
      if (this.$refs.quoteForm.validate()) {
        if (type === 4 && !emailIds) {
          var emailId = ''
          let result = this.listOfCustomers.find(x => x.id === this.quoteObj.customer_id)
          if (result) emailId = result.invoice_email ? result.invoice_email : ''
          this.emailIds = emailId
          this.showEmailDialog = true
        } else {
          this.saveLoader = true
          let quoteModel = this.$formatter.cloneVariable(this.quoteObj)
          quoteModel.invoice_date = `${quoteModel.invoice_date}T${this.$moment().format('HH:mm:ss')}`
          let transformData = { date: { list: ['date', 'due_date'], from: 'DD.MM.YYYYTHH:mm:ss', to: 'YYYY-MM-DDTHH:mm:ss' } }
          let model = this.$formatter.cloneVariable(quoteModel)
          model.order_id = this.$route.params.order_id
          model.sum_ex_vat = this.$formatter.replaceCommaWithDot(this.totalValues.sum)
          model.sum_inv_vat = this.$formatter.replaceCommaWithDot(this.totalValues.invoiceTotal)
          model.rounding_value = this.$formatter.replaceCommaWithDot(this.totalValues.rounding_value)
          model.dg_percentage = this.$formatter.replaceCommaWithDot(this.totalValues.dg_percentage)
          model = this.$formatter.formatModel(model, transformData)
          model.sale_id = this.$route.params.record_id
          model.type = 1
          switch (type) {
            case 1: // Save as Draft
              model.state = 'draft'
              break
            case 2: // Save and Download
              model.state = 'completed'
              break
            default:
              break
          }
          this.$api.saveUpdateHandler(this.MODULE_URL, model)
              .then(async (response) => {
                // this.$store.commit('isChangesMade', false)
                if (this.listOfQuoteItems.length > 0) {
                  this.saveOrUpdateQuoteItems(response._id)
                  this.quoteObj._id = response._id
                  this.quoteObj.number = response.number
                  if (type === 2) {
                    // this.downloadInvoice()
                  }
                }
                this.saveLoader = false
              }).finally(() => {
                let text = model._id ? 'updatedSuccess' : 'addedSucess'
                this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
              }).catch(() => {
                this.saveLoader = false
              })
        }
        this.validationFailedIndexes = []
      } else {
        if (this.$vuetify.breakpoint.xs) {
          this.validationFailedIndexes = []
          this.listOfQuoteItems.forEach((x, index) => x.cost_price && x.sale_price && x.qty ? false : this.validationFailedIndexes.push(index))
        }
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
        setTimeout(() => {
          var x = document.querySelectorAll(".invoiceitems .v-input--has-state")
          x.forEach(element => element.style.background = "#ff00006e" )
        })
      }
    },
    async saveActions (response, emailIds, type) {
      // this.$store.commit('isChangesMade', false)
      if (this.listOfQuoteItems.length > 0) {
        this.quoteObj.id = response.id
        this.quoteObj.number = response.number
        if (type === 4) {
          this.showEmailDialog = false
          await this.sendEmailHandler(emailIds)
        }
        if (type === 2) {
          await this.downloadInvoice()
        }
        if (type === 3) {
          let getBase64Content = await this.getInvoiceReportContent()
          let model = { content: getBase64Content, filename: `faktura_${this.quoteObj.number || ''}.pdf` }
          return this.$api.execute('post', `${this.MODULE_URL}/generate_pdf/${this.quoteObj.id}`, model)
            .then(async (response) => {
              let fileName = response.data
              let ehfModel = { id: this.quoteObj.id, type: 1, attachment_filename: fileName }
              await this.generateEHFReport(ehfModel)
            })
        }
      }
    },
    saveAndEmailHandler () {
      if (this.$refs.emailForm.validate() && this.emailIds) {
        if (this.emailIds) this.isValidEmail = true
        const getMailIDsString = this.emailIds
        const listOfMailIds = getMailIDsString.split(',')
        if (listOfMailIds.length > 0) {
          for (let i = 0; i < listOfMailIds.length; i++) {
            if (listOfMailIds[i]) {
              if (this.$formatter.validEmail(listOfMailIds[i])) this.isValidEmail = false
              else this.isValidEmail = true
            }
          }
        }
        if (!this.isValidEmail) {
          this.saveAsHandler(4, listOfMailIds)
        } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('validationIssue') })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('validationIssue') })
    },
    async sendEmailHandler (emailId) {
      if (emailId) {
        await this.downloadInvoice(true, emailId)
      }
    },
    saveOrUpdateQuoteItems (quoteId) {
      let quoteItemsModel = this.$formatter.cloneVariable(this.listOfQuoteItems)
      quoteItemsModel.map(x => x.discount = x.discount ? x.discount : '0,00')
      let transformData = { number: { list: ['qty', 'discount', 'sum_ex_vat', 'sum_inv_vat', 'vat', 'price', 'sale_price', 'cost_price'], format: 'replaceCommaWithDot' } }
      quoteItemsModel = this.$formatter.formatListModel(quoteItemsModel, transformData)
      return this.$api.execute('post', `quoteitems/bulk_save/${quoteId}`, quoteItemsModel).then(response => {
        this.getSingleRecordHandler()
      })
    },
    itemSelected (element) {
      let getIndex = this.listOfQuoteItems.findIndex(x => x === element)
      if (getIndex >= 0) {
        element.selected = !element.selected
        this.$set(this.listOfQuoteItems, getIndex, element)
      }
      this.checkForSelected()
    },
    checkForSelected () {
      let getSelectedItems = this.listOfQuoteItems.filter(x => x.selected)
      let getParentItems = this.listOfQuoteItems.filter(x => x.selected && x.is_parent_row)
      // if (getParentItems.length > 0 || getSelectedItems.length <= 0) this.disableAkonto = true
      // else this.disableAkonto = false
      if (getSelectedItems.length > 0) this.disableActions = false
      else this.disableActions = true
    },
    async addNewGroup () {
      this.addNewGroupCount++
      const allItems = this.listOfQuoteItems
      let getSelectedItems = allItems.filter(x => x.selected)
      let color = this.getRandomColor()
      if (getSelectedItems.length > 0) {
        let newGroup = { quote_id: this.quoteObj._id, color, is_parent_row: true, parent_ref: this.addNewGroupCount, qty: 0, discount: 0, sum_ex_vat: 0, price: 0, description: '', vat: 25 }
        if (this.quoteObj._id) newGroup._id = await this.newGroupHandler(newGroup)
        else newGroup._id = this.addNewGroupCount
        this.listOfQuoteItems.unshift(newGroup)
        this.moveInvoiceItemToGroup(newGroup)
      } else {
        let newGroup = { quote_id: this.quoteObj._id, color, is_parent_row: true, parent_ref: this.addNewGroupCount, qty: 1, unit: 0,  discount: 0, sum_ex_vat: 0, price: 0, description: '', vat: 25 }
        if (this.quoteObj._id) newGroup._id = await this.newGroupHandler(newGroup)
        else newGroup._id = this.addNewGroupCount
        this.listOfQuoteItems.unshift(newGroup)
      }
    },
    newGroupHandler (model) {
      return new Promise((resolve, reject) => {
        this.$api.execute('post', '/quoteitems', model)
          .then((response) => {
            if (response && response.data) resolve(response.data._id)
          })
          .catch(() => {
            reject()
          })
          .finally(() => {
            this.exportLoading = false
          })
      })
    },
    moveInvoiceItemToGroup (element) {
      if (element.is_parent_row) {
        const allItems = this.listOfQuoteItems
        allItems.filter(x => x.is_parent_row).map(x => x.selected = false)
        let getSelectedItems = allItems.filter(x => x.selected)
        const list = allItems.filter(f => !getSelectedItems.includes(f))
        this.listOfQuoteItems = list
        let getDropedIndex = allItems.findIndex(x => x === element)
        if (getDropedIndex >= 0) {
          this.listOfQuoteItems.splice(getDropedIndex + 1, 0, ...getSelectedItems.map(x => {
            x.selected = false
            x.parent_row = (element.is_parent_row && element._id) ? element._id : element.parent_ref
            x.color = element.color
            return x
          }))
          this.calculateAkontoSum(element)
        }
      }
      this.checkForSelected()
    },
    calculateAkontoSum (element) {
      if (element) {
        const allItems = this.listOfQuoteItems
        let getChildren = []
        if (this.quoteObj._id) getChildren = allItems.filter(x => x.parent_row &&  x.parent_row === element._id)
        else getChildren = allItems.filter(x => x.parent_row &&  x.parent_row  === element.parent_ref)
        // to calculate COST value
        if (getChildren.length > 0) {
          let totalCost = 0, totalQty = 0, totalCostPrice = 0, totalSalePrice= 0, totalIncludingVat = 0, totalExludingVat = 0, totalDBValue = 0
          for (let index = 0; index < getChildren.length; index++) {
            let currentRow = getChildren[index];
            if(!currentRow.is_parent_row){
              totalQty = +this.$formatter.replaceCommaWithDot(currentRow.qty)
              totalCostPrice += totalQty * +this.$formatter.replaceCommaWithDot(currentRow.price)
              totalExludingVat += +this.$formatter.replaceCommaWithDot(currentRow.sum_ex_vat)
              totalIncludingVat += +this.$formatter.replaceCommaWithDot(currentRow.sum_inv_vat)
            }
          }
          element.qty = this.$formatter.replaceDotWithComma(1)  
          element.price = this.$formatter.replaceDotWithComma(totalCostPrice)
          // element.base_price = this.$format.replaceDotWithComma(totalSalePrice)
          element.sum_inv_vat = this.$formatter.replaceDotWithComma(totalIncludingVat)
          element.sum_ex_vat = this.$formatter.replaceDotWithComma(totalExludingVat)
          element.discount = this.$formatter.replaceDotWithComma(element.discount)
          let getIndex = allItems.findIndex(x => x._id === element._id)
          this.$set(this.listOfQuoteItems, getIndex, element)
        }
      }
    },
    removeFromGroup (element) {
      let removedIndex = this.listOfQuoteItems.findIndex(x => x === element)
      if (removedIndex >= 0) {
        this.listOfQuoteItems.splice(removedIndex, 1)
      }
      let getElement = this.$formatter.cloneVariable(element)
      getElement.parent_row = null
      getElement.color = null
      this.listOfQuoteItems.push(getElement)
      let getParentId = element.parent_row
      if (getParentId) {
        let getParentObj = this.listOfQuoteItems.find(x => x.id === getParentId)
        this.calculateAkontoSum(getParentObj)
      }
    },
    removeProducts (index, item) {
      this.deletedIndex = index
      if (item._id) {
        let payload = { ids: [item._id], url: `quoteitems/delete_multiple`, module: this.MODULE_URL }
        this.$store.commit('showDeleteDialog', payload)
      } else this.listOfQuoteItems.splice(this.deletedIndex, 1)
    },
    allowDrop (ev) {
      ev.preventDefault()
    },
    drag (ev) {
      ev.dataTransfer.setData('text', '')
    },
    getRandomColor() {
      var letters = 'BCDEF'.split('')
      var color = '#';
      for (var i = 0; i < 6; i++ ) {
        color += letters[Math.floor(Math.random() * letters.length)]
      }
      return `${color}4a`
    }
  },
   beforeDestroy () {
    this.$root.$off('deleteItems')
    this.$root.$off('deleteSuccess')
  }
}
</script>
<style>
.align-right input {
  text-align: right !important;
}
.invoiceProducts textarea {
  margin-top: 2px !important;
}
.invoiceProducts th, .invoiceProducts td {
  padding: 2px !important;
}
.invoiceProducts .v-input {
  font-size: 14px !important;
}
.invoiceProducts.v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background-color: inherit;
}
.commentsArea .v-input__append-inner {
  margin-top: 5px;
}
.invoiceProducts .v-data-table__wrapper {
  overflow: unset !important;
}
.invoiceProducts .v-text-field--full-width .v-input__append-inner {
  margin-top: 4px !important;
}

.dense_table .v-text-field--full-width input {
  margin-top: 0!important;
}
.dense_table .v-text-field--full-width > .v-input__control > .v-input__slot {
  min-height: auto !important;
  border-style: none !important;
}
.dense_table .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
  padding: 0 !important;
  font-size: 14px;
  border-style: none !important;
}
.dense_table .v-select > .v-input__control > .v-input__slot {
  padding: 0 !important;
  font-size: 14px;
  border-style: none !important;
}
.dense_table .v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background: none !important;
}
.dense_table .v-text-field > .v-input__control > .v-input__slot:after {
  border-color: transparent !important;
  border-style: unset !important;
}
.dense_table .v-text-field > .v-input__control > .v-input__slot:before {
   border-style: unset !important;
}
.dense_table:not(.invoiceProducts).v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background: none !important;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 45px !important;
}
.invoiceProducts .v-text-field {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.invoiceProducts .v-input__slot, .invoiceProducts .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 1px;
}
</style>
